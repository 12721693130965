<div class="container">
    <div class="header extra-space">
        Autonomous surface vehicle prototype progress updates
    </div>
    <div>

    </div>
    <div class="hull-construction-2">
        <div class="left">
            <img src="assets/progress/h2_0.jpg">
        </div>
        <div class="right">
            <div class="img-rows">
                <img src="assets/progress/h2_1.jpg">
                <img src="assets/progress/h2_2.jpg">
            </div>
        
            <div class="img-rows">
                <img src="assets/progress/h2_3.jpg">
                <img src="assets/progress/h2_4.jpg">
            </div>
        </div>
    </div>
    <div class="header">
        23rd November: Second hull prototype. 70% weight reduction. 95% cost reduction.
    </div>
    <img src="assets/progress/e2.jpg">
    <div class="header">
        6th October: Electronic body redesigned to be fitted on vessel with higher elevation and splash protection.
    </div>
    <img src="assets/progress/e0.jpg">
    <img src="assets/progress/e1.jpg">
    <div class="header">
        30th September: Prototype electronic body and circuitry completed.
    </div>

    <div class="hull-construction">
        <div class="left">
            <img src="assets/progress/5.jpg">
        </div>
        <div class="right">
            <img src="assets/progress/0.png">
            <div class="img-rows">
                <img src="assets/progress/4.jpg">
                <img src="assets/progress/3.jpg">
                <img src="assets/progress/2.jpg">
                <img src="assets/progress/1.jpg">
            </div>
        </div>
    </div>
    <div class="header">
        23rd September: Hull body, paint and coat completed.
    </div>

    <video src="assets/progress/lidar.mp4" autoplay muted controls loop></video>
    <div class="header">
        23rd September: Combination of segmentation and depth estimation to two dimensional lidar.
    </div>

    <video src="assets/progress/path.mp4" autoplay muted controls loop></video>
    <div class="header">
        16th September: Path planning, speed and steering implementation.
    </div>

</div>