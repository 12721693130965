import { Injectable } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  readonly DEFAULT_MAX_AGE: number = 300000;

  constructor() { };

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get(key: string, fallback?: Observable<any>, cache = true, renew = true, maxAge?: number): Observable<any> | Subject<any> {
    return Observable.create((observer) => {
      if (cache && this.hasValidCachedValue(key)) {
        // console.log(`Getting from cache ${key}`);

        //Don't give out reference to original values
        // const cacheCopy = JSON.parse(JSON.stringify(this.cache.get(key).value))
        observer.next(this.cache.get(key).value);
        if (!renew) return
      }

      if (!maxAge) {
        maxAge = this.DEFAULT_MAX_AGE;
      }

      if (this.inFlightObservables.has(key)) {
        this.inFlightObservables.get(key).subscribe(observer);
      } else if (fallback && fallback instanceof Observable) {
        this.inFlightObservables.set(key, new Subject());
        this.inFlightObservables.get(key).subscribe(observer);
        // console.log(`Calling api for ${key}`);
        fallback.subscribe(value => {
          // const copy = JSON.parse(JSON.stringify(value))
          this.set(key, value, maxAge)
        }, error => {
          const inFlight = this.inFlightObservables.get(key);
          inFlight.error(error);
          inFlight.complete();
          this.inFlightObservables.delete(key);
          console.error('Error2: ' + error)
        })
      } else {
        throwError('Requested key is not available in Cache');
      }
    });
  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   */
  set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.notifyInFlightObservers(key, value);
    
    //flag the value as cached so we can determine in the app when data retreived is cached or not
    // value.cached = true;
    this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all observers of the given
   * in progress observables if observers exist.
   */
  private notifyInFlightObservers(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      const observersCount = inFlight.observers.length;
      if (observersCount) {
        //Don't give out reference to original values
        // const copy = JSON.parse(JSON.stringify(value))
        inFlight.next(value);
      }
      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}