import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CacheService } from './cache.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
};

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private _apiUrl = environment.baseAPIUrl;
  private _inferenceAPIUrl = environment.inferenceAPIUrl;
  private _statusSuccess = "success";

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
  ) { }

  post(url: string, postData: any): Observable<any> {
    const fullUrl = this._apiUrl + url;
    console.log("Getting from: " + fullUrl)

    return this.http.post<any>(fullUrl, postData).pipe(
      map(response => {
        if (response.status == this._statusSuccess) {
          return response.data;
        } else {
          throw { 'Error2': response.error };
        }
      }));
  }

  getInference(url: string, postData: any): Observable<any> {
    const fullUrl = this._inferenceAPIUrl + url;
    console.log("Getting from: " + fullUrl)

    return this.http.post<any>(fullUrl, postData).pipe(
      map(response => {
        if (response.status == this._statusSuccess) {
          return response.data;
        } else {
          throw { 'Error2': response.error };
        }
      }));
  }

  getLocal(url: string, postData?: any, cache = true, renew = true): Observable<any> {
    const fullUrl = url;
    console.log("Getting from: " + fullUrl)

    let postKey = "";
    if (postData) postKey = JSON.stringify(postData);
    const key = url + postKey;
    const observableResp = this.http.get<any>(fullUrl, { params: <any>postData })
    return this.cacheService.get(key, observableResp, cache, renew);
  }

  get(url: string, postData?: any, cache = true, renew = true): Observable<any> {
    const fullUrl = this._apiUrl + url;
    console.log("Getting from: " + fullUrl)

    let postKey = "";
    if (postData) postKey = JSON.stringify(postData);
    const key = url + postKey;
    const observableResp = this.http.get<any>(fullUrl, { params: <any>postData })
    return this.cacheService.get(key, observableResp, cache, renew);
  }


}