<div class="header">
    Demonstration of detection, identification and measurement of underwater fish species in videos and images using neural networks. 
</div>
<app-image></app-image>
<!-- <div class="details">
    <h2>Going forward</h2>
    Discuss project requirements and goals with end users

    Determine reasonable compromised between accuracy, speed and cost of neural network for project requirements

    Determine whether more data is required to achieve target detection accuracy





</div> -->
<app-progress></app-progress>
<div class="footer">
    <div class="row">Feel free to report any bugs or make inquries below</div>
    <div class="row">
        <a mat-fab color="primary" href="mailto:fish@hoa.do" target=”_blank”>
            <mat-icon>mail</mat-icon>
        </a>
    </div>
    <div class="row">Hoa Do</div>
</div>