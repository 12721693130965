import { Component, OnInit, ViewChild, ElementRef, HostListener, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropComponent } from 'ngx-file-drop';
import { versionInfo } from '../version';
import { isPlatformBrowser } from '@angular/common';

// import json_file from '../../assets/frame00.json';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('videoBar', { static: false }) videoBar: ElementRef;
  @ViewChild('thumb', { static: false }) thumb: ElementRef;
  @ViewChild('filler', { static: false }) filler: ElementRef;
  @ViewChild('NgxFileDropComponent', { static: false }) ngxFileDropComponent: NgxFileDropComponent;

  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          console.log(file.type)
          if (file.type == "image/png" || file.type == "image/jpeg") {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            this.getInference(file);
            reader.onload = () => {
              this.uploadedImage = {
                image: new Image,
                name: droppedFile.relativePath
              }
              this.uploadedImage.image.src = String(reader.result)
              this.uploadedImage.image.onload = () => {
                this.canvasLoadImg(this.uploadedImage.image, true, this.uploadedImage.name)
              }
            }
          }

        });
      }
    }
  }

  //TODO Get rid of overlapping boxes

  // @HostListener('mousemove', ['$event'])
  // onMousemove(event: MouseEvent, outerDiv: HTMLElement) {
  //   console.log(event)
  //   const bounds = outerDiv.getBoundingClientRect();
  //   const posX = event.clientX - bounds.left;
  //   const posY = event.clientY - bounds.top;
  //   console.log(posX, posY);
  // }

  // onMouseMove(event: MouseEvent, outerDiv: HTMLElement) {
  //   const bounds = outerDiv.getBoundingClientRect();
  //   const posX = event.clientX - bounds.left;
  //   const posY = event.clientY - bounds.top;
  //   console.log(posX, posY);
  // }

  showThumb = false;
  uploadedImage;

  onMouseOverVideoBar() {
    this.showThumb = true;
  }
  onMouseOutVideoBar() {
    this.showThumb = false;
    this.updateFillerBar()
  }

  formatLabel(value: number) {
    return value + "%";
  }

  openFileSelector() {
    this.ngxFileDropComponent.openFileSelector()
  }

  onMouseClickVideoBar(event: MouseEvent, outerDiv: HTMLElement) {
    if (this.img_preds) {
      const posX = event.clientX - outerDiv.offsetLeft;

      let relativeFrame = posX / outerDiv.clientWidth
      relativeFrame = Math.floor(relativeFrame * 100)
      relativeFrame = Math.round(relativeFrame / 100 * (this.img_preds.length - 1))
      relativeFrame = Math.max(0, relativeFrame)
      relativeFrame = Math.min(relativeFrame, this.img_preds.length - 1)
      this.thumb_image = this.seletectedModel.imageRoot + this.json_file['images'][relativeFrame]['filename']
      this.skipToFrame(relativeFrame)
    }
  }

  onMouseMoveVideoBar(event: MouseEvent, outerDiv: HTMLElement) {
    if (this.img_preds) {
      const posX = event.clientX - outerDiv.offsetLeft;
      const posY2 = outerDiv.offsetTop - this.thumb.nativeElement.offsetHeight

      let thumbX = event.clientX;
      if (posX > outerDiv.clientWidth - this.thumb.nativeElement.offsetWidth) {
        thumbX = outerDiv.offsetLeft + outerDiv.clientWidth - this.thumb.nativeElement.offsetWidth
      }

      this.renderer.setStyle(this.thumb.nativeElement, "top", posY2 + "px");
      this.renderer.setStyle(this.thumb.nativeElement, "left", thumbX + "px");

      this.renderer.setStyle(this.filler.nativeElement, "width", posX / outerDiv.offsetWidth * 100 + "%");

      let relativeFrame = posX / outerDiv.offsetWidth
      relativeFrame = Math.floor(relativeFrame * 100)
      relativeFrame = Math.round(relativeFrame / 100 * (this.img_preds.length - 1))
      relativeFrame = Math.max(0, relativeFrame)
      relativeFrame = Math.min(relativeFrame, this.img_preds.length - 1)
      this.thumb_image = this.seletectedModel.imageRoot + this.json_file['images'][relativeFrame]['filename']
    }

  }

  ctx: CanvasRenderingContext2D;
  colours = [
    [0, 255, 0], [0, 0, 255], [255, 0, 0], [0, 255, 255],
    [255, 255, 0], [255, 0, 255],
    [245, 145, 50]
  ]

  public isBrowser: boolean;

  constructor(
    private apiService: ApiService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    console.log("Build date: " + versionInfo.buildDate);
  }

  ngAfterViewInit() {
    this.ctx = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');
    this.getJson()
  }

  models = [
    { name: "[BEST ID] FRCNNPT_C127_E50_test_set", imageRoot: "assets/images/test_frames/", jsonPath: "FRCNNPT_C127_E50_test" },

    { name: "[BEST COUNT] FRCNNPT_C1_E50_2_test_set", imageRoot: "assets/images/test_frames/", jsonPath: "FRCNNPT_C1_E50_2_test", type: "FRCNNC1" },

    { name: "X101FPNPT_CL1_test", imageRoot: "assets/images/test_frames/", jsonPath: "X101FPNPT_CL1_test" },
    { name: "Cascade_ResNetPT_CL1_LR02_test", imageRoot: "assets/images/test_frames/", jsonPath: "Cascade_ResNetPT_CL1_LR02_test" },

    // { name: "X101FPNPT_CL1_train", imageRoot: "assets/images/train_frames/", jsonPath: "X101FPNPT_CL1_train" },
    // { name: "Cascade_ResNetPT_CL1_LR02_train", imageRoot: "assets/images/train_frames/", jsonPath: "Cascade_ResNetPT_CL1_LR02_train" },
    // { name: "FRCNNPT_C1_E50_2_train", imageRoot: "assets/images/train_frames/", jsonPath: "FRCNNPT_C1_E50_2_train" },

    // { name: "FRCNNS_C620_E50", imageRoot: "assets/images/frames/", jsonPath: "FRCNNS_C620_E50" },
    // { name: "FRCNNPT_C127_E50", imageRoot: "assets/images/frames/", jsonPath: "FRCNNPT_C127_E50" },
    // { name: "FRCNNPT_C127_E50_train_set", imageRoot: "assets/images/train_frames/", jsonPath: "FRCNNPT_C127_E50_train" },

    // { name: "X101FPNPT_CL127", imageRoot: "assets/images/frames/", jsonPath: "X101FPNPT_CL127" },
    // { name: "X101FPNPT_CL127_train_set", imageRoot: "assets/images/train_frames/", jsonPath: "X101FPNPT_CL127_train" },
    // { name: "X101FPNPT_CL127_test_set", imageRoot: "assets/images/test_frames/", jsonPath: "X101FPNPT_CL127_test" },

    // { name: "Cascade_ResNetPT_CL127_LR02", imageRoot: "assets/images/frames/", jsonPath: "Cascade_ResNetPT_CL127_LR02" },
    // { name: "Cascade_ResNetPT_CL127_LR02_train_set", imageRoot: "assets/images/train_frames/", jsonPath: "Cascade_ResNetPT_CL127_LR02_train" },
    // { name: "Cascade_ResNetPT_CL127_LR02_test_set", imageRoot: "assets/images/test_frames/", jsonPath: "Cascade_ResNetPT_CL127_LR02_test" },
    // { name: "Cascade_ResNetS_CL620_LR02", imageRoot: "assets/images/frames/", jsonPath: "Cascade_ResNetS_CL620_LR02" },

    // { name: "Cascade_ResNet_CL620_LR02_VIDEO", imageRoot: "assets/images/frame00/", jsonPath: "V_Cascade_ResNet_CL620_LR02" },
    // { name: "FRCNNS_C620_E50_VIDEO", imageRoot: "assets/images/frame00/", jsonPath: "V_FRCNNS_C620_E50" },
    // { name: "X101FPNPT_CL620_VIDEO", imageRoot: "assets/images/frame00/", jsonPath: "X101FPNPT_CL620" },

  ]
  seletectedModel = this.models[0]

  imageListIndex = 0;


  //SETTINGS
  ISADMIN = false;
  disableOverlapFilter = false
  imageBuffer = {};
  currentFrame = 48;
  confidence = 10;
  playInterval = null;
  frameCloseness = 30;
  img_preds
  classes
  thumb_image
  classInVideo
  filtered_preds
  showClassLabels = true;
  showBoxes = true;
  showDepth = false;
  overLapRatio = 45
  frameChanged = true

  showDepthChanged() {
    this.frameChanged = true
    this.loadFrame()
  }


  modelChanged() {
    if (this.currentFrame > 60) this.currentFrame = 60
    this.getJson()
  }

  json_file;
  isLoadingJson = false;

  getJson() {
    this.clearCanvas()
    this.isLoadingImage = true
    let model = this.seletectedModel
    let json_path = "assets/models/" + model.jsonPath + ".json"
    this.classInVideoLoaded = false;
    this.isLoadingJson = true;
    if (!this.imageBuffer[model.imageRoot]) this.imageBuffer[model.imageRoot] = []
    this.apiService.getLocal(json_path, null, true, false).subscribe(data => {
      this.json_file = data;
      this.img_preds = this.json_file['images']

      this.classes = this.json_file['classes']
      if (model.type == "FRCNNC1") {
        this.classes.unshift("")
      }
      this.analyzeVideo()
      this.getImages(model.imageRoot, this.img_preds)
      console.log(model.imageRoot)
      this.frameChanged = true
      this.isLoadingJson = false;
    }, error => console.log(error))

  }

  getBufferItem(imageRoot, imageList, frame) {
    let bufferItem = {
      image: new Image,
      depth: new Image,
      loaded: false
    }

    // console.log(imageRoot)
    // console.log(imageList)
    // console.log(frame)

    let depth_path = imageList[frame]['filename'].slice(0, -4) + "_depth.jpg"
    bufferItem.depth.src = imageRoot + "depth/" + depth_path
    bufferItem.image.src = imageRoot + imageList[frame]['filename']

    bufferItem.image.onload = () => {
      bufferItem.loaded = true
      if (!this.uploadMode) {
        if (this.frameChanged
          && imageRoot == this.seletectedModel.imageRoot
          && this.imageBuffer[imageRoot][this.currentFrame]
          && this.imageBuffer[imageRoot][this.currentFrame].loaded) {
          this.frameChanged = false;
          this.canvasLoadImg(this.imageBuffer[imageRoot][this.currentFrame])
          this.getImages(imageRoot, imageList)
        }

        if (!this.classInVideoLoaded) {
          this.classInVideo.forEach(item => {
            if (item.frame == frame) item.loaded = true
          })
          this.classInVideoLoaded = this.classInVideo.every(item => item.loaded === true)
        }
      }
    }

    return bufferItem
  }

  classInVideoLoaded = false;

  getImages(imageRoot, imageList) {
    if (!this.imageBuffer[imageRoot][this.currentFrame]) {
      let imageBuffer = this.getBufferItem(imageRoot, imageList, this.currentFrame)
      this.imageBuffer[imageRoot][this.currentFrame] = imageBuffer
    } else {
      this.loadFrame()
    }

    if (!this.isLoadingImage) {
      console.log("start Loading all images")
      imageList.forEach((image, index) => {
        let imageBuffer = this.getBufferItem(imageRoot, imageList, index)
        if (!this.imageBuffer[imageRoot][index]) this.imageBuffer[imageRoot][index] = imageBuffer
      });
    }

    if (!this.classInVideoLoaded && this.classInVideo) {
      this.classInVideoLoaded = this.classInVideo.every(item => item.loaded === true)
    }

  }

  getBoxIOU(bbox1, bbox2) {
    let [Ax0, Ay0, Ax1, Ay1] = bbox1
    let [Bx0, By0, Bx1, By1] = bbox2

    if (Ax0 < Ax1 &&
      Ay0 < Ay1 &&
      Bx0 < Bx1 &&
      By0 < By1
    ) {

      let x_left = Math.max(Ax0, Bx0)
      let y_top = Math.max(Ay0, By0)
      let x_right = Math.min(Ax1, Bx1)
      let y_bottom = Math.min(Ay1, By1)

      if (x_right < x_left || y_bottom < y_top) return 0

      let inter_area = (x_right - x_left) * (y_bottom - y_top)

      let box1_area = (Ax1 - Ax0) * (Ay1 - Ay0)
      let box2_area = (Bx1 - Bx0) * (By1 - By0)
      let union_area = box1_area + box2_area - inter_area

      let IOU = inter_area / union_area

      return IOU
    } else return 0
  }

  analyzeVideo() {
    let classInVideo = [];

    this.filtered_preds = JSON.parse(JSON.stringify(this.img_preds))

    //Filter confidence
    this.filtered_preds.forEach((preds, index) => {
      //Scores are sorted from highest to lowest. Slice every index after score threshold
      let indexScore = preds.scores.findIndex(score => score < this.confidence)
      if (indexScore >= 0) {
        preds.boxes.length = indexScore
        preds.classes.length = indexScore
        preds.scores.length = indexScore
        if (preds.lengths) preds.lengths.length = indexScore
      }

      if (!this.disableOverlapFilter) {
        let boxOverLapIndex = []
        preds.boxes.forEach((box1, i) => {
          preds.boxes.forEach((box2, j) => {
            // if (index == 5 && i < 5 && j < 5) {
            //   console.log(this.getBoxIOU(box1, box2))
            //   console.log(box1, box2)
            // }

            if (i < j && !boxOverLapIndex.includes(j) && this.getBoxIOU(box1, box2) > this.overLapRatio / 100) {
              boxOverLapIndex.push(j)
            }
          })
        });

        //Sort by desc so we can splice without changing index of elements
        boxOverLapIndex.sort((a, b) => b - a).forEach(index => {
          preds.boxes.splice(index, 1)
          preds.classes.splice(index, 1)
          preds.scores.splice(index, 1)
          if (preds.lengths) preds.lengths.splice(index, 1)
        })
      }
    })


    this.filtered_preds.forEach((preds, frame) => {
      preds.classes.forEach((class_id, i) => {
        let classInfo = classInVideo.find(item => {
          if (item.class_id == class_id && item.frame == frame) return true
        })
        if (!classInfo) {
          classInfo = {
            class_id: class_id,
            class_name: this.classes[class_id],
            frame: frame,
            count: 0
          }
          classInVideo.push(classInfo)
        }
        classInfo.count++

        // preds.classes.forEach((class_id, i) => {

        // }

      })
    })

    //Remove bbox with high overlaps
    //Get max of each class
    let classMax = []
    classInVideo.forEach(item => {
      if (item.class_id in classMax) {
        if (item.count > classMax[item.class_id]) classMax[item.class_id] = item.count
      } else classMax[item.class_id] = item.count
    })

    //Filter for frames with 90% of max
    classInVideo = classInVideo.filter(item => item.count >= classMax[item.class_id] * 0.9)

    //Filter for frames that are too close
    classInVideo.forEach((item, i) => {
      classInVideo.forEach((otherItems, j) => {
        if (i < j) {
          if (item.class_id == otherItems.class_id &&
            Math.abs(item.frame - otherItems.frame) < this.frameCloseness)
            classInVideo.splice(j, 1)
        }
      })
    })

    classInVideo.sort((a, b) => b.count - a.count)

    classInVideo.forEach(item => {
      if (this.imageBuffer[this.seletectedModel.imageRoot][item.frame]) {
        item.loaded = this.imageBuffer[this.seletectedModel.imageRoot][item.frame].loaded

        // if (item.frame == image)
      }

    })
    if (!this.classInVideoLoaded) {
      this.classInVideoLoaded = classInVideo.every(item => item.loaded === true)

    }
    // console.log(this.classInVideoLoaded)

    // console.log(classInVideo)

    this.classInVideo = classInVideo
  }

  play() {
    clearInterval(this.playInterval)
    this.playIntervalCall()
    this.playInterval = setInterval(() => {
      this.playIntervalCall()
    }, 1000);
  }

  playIntervalCall() {
    if (this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame + 1]) {
      if (!this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame + 1].loaded) {
        this.isLoadingImage = true
      } else {
        this.currentFrame++
        this.canvasLoadImg(this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame])
        this.updateFillerBar()
      }
    }

    if (this.currentFrame >= this.imageBuffer[this.seletectedModel.imageRoot].length - 1) {
      this.pause()
    }
  }

  updateFillerBar() {
    this.renderer.setStyle(
      this.filler.nativeElement,
      "width", (this.currentFrame + 1) / this.imageBuffer[this.seletectedModel.imageRoot].length * 100 + "%");
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  previousFrame() {
    if (this.currentFrame > 0) {
      this.clearCanvas()
      this.frameChanged = true
      this.currentFrame--
      this.canvasLoadImg(this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame])
      this.pause()
    }
  }

  nextFrame() {
    if (this.currentFrame < this.filtered_preds.length) {
      this.clearCanvas()
      this.frameChanged = true
      this.currentFrame++
      this.canvasLoadImg(this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame])
      this.pause()
    }
  }

  isLoadingImage = true;

  skipToFrame(frame) {
    if (!this.uploadMode && !this.isLoadingJson) {
      this.clearCanvas()
      this.frameChanged = true
      this.currentFrame = frame
      this.getImages(this.seletectedModel.imageRoot, this.img_preds)
    }
  }

  loadFrame() {
    if (!this.uploadMode) {
      if (this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame].loaded) {
        this.canvasLoadImg(this.imageBuffer[this.seletectedModel.imageRoot][this.currentFrame])
      } else {
        this.isLoadingImage = true
      }
    } else {
      this.canvasLoadImg(this.uploadedImage.image, true, this.uploadedImage.name)
      this.analyzeVideo()
      this.drawRec(this.filtered_preds[0])
    }
  }

  pause() {
    clearInterval(this.playInterval)
    this.playInterval = null
  }

  confidenceChanged(event) {

    this.confidence = event.value
    if (this.uploadMode) {

      this.canvasLoadImg(this.uploadedImage.image, true, this.uploadedImage.name)
      this.analyzeVideo()
      this.drawRec(this.filtered_preds[0])
    } else {
      this.analyzeVideo()

      this.loadFrame()

    }

    // console.log(this.classInVideoLoaded)
    // console.log(this.classInVideo)
  }

  overLapRatioChanged(event) {
    this.overLapRatio = event.value

    this.analyzeVideo()
    this.loadFrame()
  }

  canvasLoadImg(image, isUpload = false, filename = null) {
    if (!isUpload) {
      if (this.showDepth) {
        image = image.depth
      } else {
        image = image.image
      }
    }
    this.isLoadingImage = false;
    this.canvas.nativeElement.width = image.width;
    this.canvas.nativeElement.height = image.height;
    this.ctx.drawImage(image, 0, 0);
    this.ctx.fillStyle = "white";
    this.ctx.font = "30px Arial";
    this.ctx.fillText("Fish detector alpha v0.0.1998", image.width - 410, image.height - 20);

    if (!isUpload) {
      this.ctx.fillText(`Frame: ${this.currentFrame}`, image.width - 150, 40);
      this.ctx.fillText(`Frame: ${this.currentFrame} - ${this.filtered_preds[this.currentFrame]['filename']}`, 30, image.height - 20);
      this.drawRec(this.filtered_preds[this.currentFrame])
    } else if (filename) {
      this.ctx.fillText(`${filename}`, 30, image.height - 20);
    }
  }

  mouseOverKeyPoint(item) {
    item.mouseOver = true
  }

  mouseOutKeyPoint(item) {
    item.mouseOver = false
  }

  drawRec(preds) {
    let boxes = preds['boxes']
    const classIds = preds['classes']
    const scores = preds['scores']
    const lengths = preds['lengths']
    const colorMap = {}
    classIds.forEach(id => {
      if (typeof colorMap[id] === 'undefined') {
        //We don't want to draw too many otherwise legend will be full
        if (Object.keys(colorMap).length < this.colours.length) {
          colorMap[id] = this.colours[Object.keys(colorMap).length]

          //get Count
          let count = classIds.filter((id2) => (id2 === id)).length;

          //draw legend
          let [r, g, b] = colorMap[id]
          this.ctx.font = "30px Arial";
          this.ctx.fillStyle = `rgb(${r}, ${g}, ${b})`

          //Get offset
          let offset = Object.keys(colorMap).length
          this.ctx.fillText(count + " x [" + id + "] " + this.classes[id], 15, 5 + 40 * offset);
        }
      }
    });
    if (boxes) {
      boxes.forEach((box, i) => {
        this.ctx.lineWidth = 2;

        let [r, g, b] = this.colours[0]
        if (colorMap[classIds[i]]) [r, g, b] = colorMap[classIds[i]]

        this.ctx.strokeStyle = `rgb(${r}, ${g}, ${b})`
        this.ctx.fillStyle = `rgb(${r}, ${g}, ${b})`
        this.ctx.font = "30px Arial";
        if (this.showClassLabels) {
          if (lengths) {
            this.ctx.fillText(`${lengths[i]}mm  ${Math.round(scores[i])}%`, box[0], box[1]);
          } else {
            this.ctx.fillText(`${Math.round(scores[i])}%`, box[0], box[1]);
          }
        }

        if (this.showBoxes) {
          const w = box[2] - box[0];
          const h = box[3] - box[1];
          this.ctx.beginPath();
          this.ctx.rect(box[0], box[1], w, h);
          this.ctx.stroke();
        }
      })
    }
  }

  //OLD STUFF

  uploadMode = false;

  uploadModeChanged() {
    if (this.uploadMode) {
      this.classInVideo = null
      if (this.uploadedImage) {
        this.canvasLoadImg(this.uploadedImage.image, true, this.uploadedImage.name)
        this.img_preds = this.uploadedImage.preds
        this.analyzeVideo()
        this.drawRec(this.filtered_preds[0])
      }
      else this.clearCanvas()
    }
    else {
      this.getJson()
    }
  }

  gettingInference = false;

  getInference(image) {
    this.gettingInference = true
    const uploadData = new FormData();
    uploadData.append('file', image, image.name);
    this.apiService.getInference("predict", uploadData).subscribe(res => {
      this.uploadedImage.preds = [res]
      this.img_preds = this.uploadedImage.preds
      this.analyzeVideo()
      this.drawRec(this.filtered_preds[0])
      this.gettingInference = false
      this.ERROR = false
    }, error => {
      console.log(error)
      this.gettingInference = false
      this.ERROR = true
    })
  }

  ERROR = false

  selectPrediction(i) {
    // this.imageData.data.set(this.originalImageData.data);
    // this.ctx.putImageData(this.imageData, 0, 0);
    // this.applyOverlay(this.imageData, this.masks[i])
  }


  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // applyOverlay(imageData, mask) {
  //   let data = imageData.data
  //   let color = this.colours[this.getRndInteger(0, this.colours.length)]
  //   let w = 0.5;
  //   for (var i = 0; i < mask.length; i++) {
  //     if (mask[i] > 0.5 * 255) {
  //       let x = i * 4;
  //       data[x] = data[x] * w + color[0] * (1 - w);     // red
  //       data[x + 1] = data[x + 1] + color[1] * (1 - w); // green
  //       data[x + 2] = data[x + 2] + color[2] * (1 - w); // blue
  //     }
  //   }
  //   this.ctx.putImageData(imageData, 0, 0);
  // }

  // invert(imgData) {
  //   for (var i = 0; i < imgData.length; i += 4) {
  //     imgData[i] = 255 - imgData[i];     // red
  //     imgData[i + 1] = 255 - imgData[i + 1]; // green
  //     imgData[i + 2] = 255 - imgData[i + 2]; // blue
  //   }
  // };


}
