<div class="container">
    <div class="main">
        <mat-button-toggle-group [(ngModel)]="uploadMode" (change)="uploadModeChanged()">
            <mat-button-toggle [value]="false">View</mat-button-toggle>
            <mat-button-toggle [value]="true">
                <div class="inline">
                    <div class="numberCircle upload-number">6</div>Upload
                </div>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="video-container">
            <div class="invisible-placeholder"></div>
            <div class="canvas-container">
                <div *ngIf="uploadMode" class="overLapping-drop">
                    <ngx-file-drop #NgxFileDropComponent [dropZoneClassName]="uploadedImage ? 'dropzone2' : 'dropzone'"
                        multiple="false" (onFileDrop)="dropped($event)" accept=".png,.jpg,.jpeg">
                        <ng-template *ngIf="!uploadedImage" ngx-file-drop-content-tmp
                            let-openFileSelector="openFileSelector">
                            Drop png or jpg file to upload and perform inference
                        </ng-template>
                    </ngx-file-drop>
                </div>
                <canvas #canvas *ngIf="isBrowser">HTML5 Canvas is required for this app</canvas>
            </div>
            <div *ngIf="gettingInference || isLoadingImage">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <div *ngIf="gettingInference">Please wait. Performing inference...
                </div>
                <div *ngIf="ERROR">An error occured...
                </div>
            </div>
            <div *ngIf="!uploadMode" matBadge="4" class="video-bar" #videoBar
                (mousemove)="onMouseMoveVideoBar($event, videoBar)" (mouseover)="onMouseOverVideoBar()"
                (mouseout)="onMouseOutVideoBar()" (click)="onMouseClickVideoBar($event, videoBar)">
                <div #filler class="filler"></div>
            </div>
            <div *ngIf="!uploadMode" class="keypoints">
                <ng-container *ngFor="let item of classInVideo; index as i">
                    <div class="keypoint" *ngIf="item.loaded" (click)="skipToFrame(item.frame)"
                        (mouseover)="mouseOverKeyPoint(item)" (mouseout)="mouseOutKeyPoint(item)"
                        [ngStyle]="{'left.%': item.frame/img_preds.length*100 }"
                        [ngClass]="item.mouseOver ? 'highlighted' : ''">
                        <img height="20px" src="assets/fish.svg" onerror="this.src='assets/fish.png'">
                    </div>
                </ng-container>
            </div>
            <div class="controls">
                <div class="control-left">
                    <mat-form-field *ngIf="!uploadMode" matBadge="5" class="model-select full-width desktop"
                        color="primary" appearance="fill">
                        <mat-label>Model_Classes_imageSet</mat-label>
                        <mat-select (selectionChange)="modelChanged()" [(value)]="seletectedModel">
                            <mat-option *ngFor="let model of models" [value]="model">
                                {{ model.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="filler" *ngIf="uploadMode">
                        <a *ngIf="uploadedImage">Drop more files over image above or use the browse button.</a>
                    </div>

                    <mat-slide-toggle [(ngModel)]="showBoxes" (change)="loadFrame()">Boxes</mat-slide-toggle>
                    <mat-slide-toggle [(ngModel)]="showClassLabels" (change)="loadFrame()">Labels
                    </mat-slide-toggle>
                    <mat-slide-toggle *ngIf="!uploadMode" matBadgePosition="before" matBadge="2" [(ngModel)]="showDepth"
                        (change)="showDepthChanged()">Depth
                    </mat-slide-toggle>

                </div>

                <div *ngIf="uploadMode" class="control-right">
                    <button mat-button (click)="openFileSelector()">Browse Files</button>
                </div>

                <div *ngIf="!uploadMode" class="control-right">
                    <button mat-icon-button (click)="previousFrame()">
                        <mat-icon>skip_previous</mat-icon>
                    </button>
                    <button *ngIf="!playInterval" mat-icon-button (click)="play()">
                        <mat-icon>play_arrow</mat-icon>
                    </button>
                    <button *ngIf="playInterval" mat-icon-button (click)="pause()">
                        <mat-icon>pause</mat-icon>
                    </button>
                    <button mat-icon-button (click)="nextFrame()">
                        <mat-icon>skip_next</mat-icon>
                    </button>
                </div>

            </div>
            <div class="model-select">
                <mat-form-field *ngIf="!uploadMode" matBadge="5" class="full-width mobile" color="primary"
                    appearance="fill">
                    <mat-label>Model_Classes_imageSet</mat-label>
                    <mat-select (selectionChange)="modelChanged()" [(value)]="seletectedModel">
                        <mat-option *ngFor="let model of models" [value]="model">
                            {{ model.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>

        </div>

    </div>
    <div class="right-nav">
        <div class="col">
            <div class="title">Taxa detected in image set</div>
            <div class="slider-container">
                <div matBadgePosition="before" matBadge="3" class="label">Confidence</div>
                <mat-slider [disabled]="gettingInference" color="primary" thumbLabel [displayWith]="formatLabel"
                    tickInterval="1" min="0" max="100" [value]="confidence" (input)="confidenceChanged($event)">
                </mat-slider>
            </div>
            <div *ngIf="ISADMIN" class="slider-container">
                <div class="label">OverlapFilter</div>
                <mat-slider color="primary" thumbLabel tickInterval="1" min="0" max="100" [value]="overLapRatio"
                    (input)="overLapRatioChanged($event)"></mat-slider>
            </div>
            <h5>*Duplicates indicates that multiple maximas were detected</h5>
            <div matBadgePosition="before" matBadge="1" class="row">
                <div class="item-id">ID</div>
                <div class="item-name">Name</div>
                <div class="count">Count</div>
                <!-- <div class="frame">{{ class.frame }}</div> -->
            </div>

            <ng-container *ngFor="let item of classInVideo; index as i">
                <div class="row" *ngIf="item.loaded || uploadMode" (click)="skipToFrame(item.frame)"
                    (mouseover)="mouseOverKeyPoint(item)" (mouseout)="mouseOutKeyPoint(item)" hidden="hidden"
                    [ngClass]="item.mouseOver ? 'highlighted' : ''">
                    <div class="item-id">{{ item.class_id }}</div>
                    <div class="item-name">{{ item.class_name }}</div>
                    <div class="count">{{ item.count }}</div>
                    <!-- <div class="frame">{{ item.frame }}</div> -->
                </div>
            </ng-container>
            <div class="spinner-container" *ngIf="gettingInference || !classInVideoLoaded && !uploadMode">
                <mat-spinner class="class-spinner" color="warn" diameter="50" strokeWidth="5"></mat-spinner>
                <div *ngIf="!uploadMode" class="loading-text">Loading images</div>
                <div *ngIf="uploadMode" class="loading-text">Performing inference</div>

            </div>
            <div class="row padding-5" *ngIf="!gettingInference && classInVideo && classInVideo.length <= 0">Looks like
                it didn't detect anything :(</div>
        </div>
        <div class="col info">
            <div class="title">Guide</div>
            <div class="intro">
                <span>
                    Legend: <br> (Detections in frame) x [ (ID) ] (Taxa)
                </span>
                <br>
                <br>
                <span>
                    Bounding box coresponding to the location of the detected fish. Numbers above showing estimated length of fish and the
                    model's confidence in the detection respectively.
                </span>
            </div>
            <div class="header2">Interface</div>
            <div class="step-lines">
                <div class="numberCircle">1</div>
                <span>
                    List of identified taxa in the image list/video and their maximal counts. Click on a row to jump to
                    the identified frame. Alternatively click on <img class="fish_legend" height="20px"
                        src="assets/fish.svg" onerror="this.src='assets/fish.png'"> below the image.
                </span>
            </div>
            <div class="step-lines">
                <div class="numberCircle">2</div>
                <span>
                    A separate neural network is used to estimate the depth of each pixel.
                    This information is used to estimate the length of the fish. Estimates are rudimentary at this
                    stage.
                    Toggle to view the depth map.
                </span>
            </div>
            <div class="step-lines">
                <div class="numberCircle">3</div>The model's confidence in detection. Slide to filter.
            </div>
            <div class="step-lines">
                <div class="numberCircle">4</div>Hover mouse over bar to preview frame, click to jump to frame or simply
                press the play button
            </div>
            <div class="step-lines">
                <div class="numberCircle">5</div>View different neural network models and image sets. The neural nets
                have never seen the test sets prior to inference. They received data only from the train set.
            </div>
            <div class="step-lines">
                <div class="numberCircle">6</div>Upload your own image for inference. Images from AIMS fish dataset are
                recommended.
            </div>
        </div>
    </div>
</div>

<!-- <p>image works!</p> -->


<!-- <h3>Classes</h3> -->
<!-- <a *ngFor="let score of scores; index as i" (click)="selectPrediction(i)">{{ score | number:'1.2-2' }} -
    {{ classes[labels[i]] }} <br></a> -->

<img #thumb class="thumb" [hidden]="!showThumb" [src]="thumb_image">